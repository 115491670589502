$(function() {

	var $blocks = $('.invisible');
	var $window = $(window);

	// This is to calculate height for "movie container"
	var navbar = $("header").outerHeight();
    var firstHeight = $(window).height() - navbar;
    var width = $(window).width();

    // and now add CSS for height
    $('#movie').css({"height":firstHeight + "px"});
    // if NOT "small" remove the cover pic so we can watch movie
    if( width > 576 ) { $("#movie").css("background", "none"); };

    // Whenever the screen or window is moved
	$(document.body).on('touchmove', onScroll);
	$window.on('scroll', onScroll);

	// Do we need to
	function onScroll() {
			$blocks.each(function(i,elem){
			if($(this).hasClass('visible'))
				return;

				isScrolledIntoView($(this));
		});
	}

	// Scroll to a selected anchor
    $('a[href^="#"]').on('click', function(event) {
        var target = $(this.getAttribute('href'));
        if( target.length ) {
            event.preventDefault();
            $('html, body').animate({
                scrollTop: target.offset().top
            }, 1500);
        }
    });

});

function isScrolledIntoView(elem) {
	var docViewTop = $(window).scrollTop();
	var docViewBottom = docViewTop + $(window).height();
	var elemOffset = 0;

	/*if(elem.data('offset') != undefined) {
		elemOffset = elem.data('offset');
	}*/

	var elemTop = $(elem).offset().top;
	var elemBottom = elemTop + $(elem).height();

	if(elemOffset != 0) { // custom offset is updated based on scrolling direction
		if(docViewTop - elemTop >= 0) {
			// scrolling up from bottom
			elemTop = $(elem).offset().top + elemOffset;
		} else {
			// scrolling down from top
			elemBottom = elemTop + $(elem).height() - elemOffset
		}
	}

	if((elemBottom <= docViewBottom) && (elemTop >= docViewTop)) {

		var animElemsLeft = $('.invisible').length;
		console.log("elemBottom: " + elemBottom);
		console.log("docViewBottom: " + docViewBottom);
		// Alternate to slide in left then right
		if (isOdd(animElemsLeft) != 1){
			$(elem).removeClass('invisible').addClass('visible animate__animated animate__slideInLeft');
		}else{
			$(elem).removeClass('invisible').addClass('visible animate__animated animate__slideInRight');
		}

		// with no animated elements left debind the scroll event
		if(animElemsLeft == 0){
			$(window).off('scroll');
		}
	}
}

function isOdd(num) { return num % 2;}

function isMobile() {
	try{ document.createEvent("TouchEvent"); return true; }
	catch(e){ return false; }
}
